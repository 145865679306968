import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import Header from './Header';
import Home from './Home'
import About from './About'
import Services from './Services'
import Contact from './Contact'
import Faq from './Faq'
import Testimonials from './Testimonials'
import Footer from './Footer';
import JobSeekerPage from './JobSeekerPage';
import Positions from './Positions';
import Admin from './Admin';

function App() {
  return (
    <Router>
      <div className='container'>
        <Header />

        <Routes>
          <Route path='/' exact element={<Home />} />
          <Route path='/about' exact element={<About />} />
          <Route path='/services' exact element={<Services />} />
          <Route path='/contact' exact element={<Contact />} />
          <Route path='/job-seeker' exact element={<JobSeekerPage />} />
          <Route path='/faq' exact element={<Faq />} />
          <Route path='/testimonials' exact element={<Testimonials />} />
          <Route path='/positions' exact element={<Positions />} />
          <Route path='/create-positions' exact element={<Admin />} />
        </Routes>

        <Footer />
      </div>

    </Router>
  );
}

export default App;
