import './SgLogo.css'
import Logo from '../images/sagaminds.jpeg'


function SgLogo() {
    return (
        <div className="logo">
            <img src={Logo} alt="Sagacious Minds" width="285px" height="100px" />
        </div>
    );
}

export default SgLogo;