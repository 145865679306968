import './JobSeeker.css'
import UploadCV from "../images/uploadcv.jpg"
import { useState } from 'react'
import { postData } from '../helper'

function JobSeeker({ showImage, appliedPosition, appliedLocation, appliedCtc }) {

    const [fullName, setFullName] = useState('')
    const [email, setEmail] = useState('')
    const [phone, setPhone] = useState('')
    const [position, setPosition] = useState(appliedPosition)
    const [location, setLocation] = useState(appliedLocation)
    const [ctc, setCtc] = useState(appliedCtc)
    const [resume, setResume] = useState('')
    const [error, setError] = useState(false)
    const [success, setSuccess] = useState('')

    const onFullNameChange = (e) => {
        setFullName(e.target.value)
    }

    const onEmailChange = (e) => {
        setEmail(e.target.value)
    }

    const onPhoneChange = (e) => {
        setPhone(e.target.value)
    }

    const onPositionChange = (e) => {
        setPosition(e.target.value)
    }

    const onLocationChange = (e) => {
        setLocation(e.target.value)
    }

    const onCtcChange = (e) => {
        setCtc(e.target.value)
    }

    const onResumeChange = (e) => {
        setResume(e.target.files[0])
    }

    const onFormSubmit = (e) => {
        e.preventDefault()
        if (!fullName || !email || !phone || !position || !location || !ctc || !resume) {
            setError(true)
            return
        }
        const formData = new FormData()
        formData.append('resume', resume)
        formData.append('fullName', fullName)
        formData.append('email', email)
        formData.append('phone', phone)
        formData.append('position', position)
        formData.append('location', location)
        formData.append('ctc', ctc)
        const headers = {
            'Content-Type': 'multipart/form-data'
        }

        const response = postData('job-seeker', formData, headers)
        response.then(res => {
            setError(false)
            if ("status_code" in res.data && res.data.status_code !== 200) {
                if ("detail" in res.data) {
                    setSuccess(res.data.detail)
                }
                else {
                    setSuccess("Ooops something went wrong...")
                }
                return
            }
            setSuccess(res.data.message)
            setFullName('')
            setEmail('')
            setPhone('')
            setPosition('')
            setLocation('')
            setCtc('')
            setResume('')
        }).catch(err => {
            console.log(err)
        })


    }

    return (

        <div className='seeker-form-div'>
            <form className='seeker-form'>
                {error && <div className='error'>Please fill all the fields</div>}
                {success && <div className='success'>{success}</div>}
                <input type='text' placeholder='Full Name' onChange={onFullNameChange} value={fullName} />
                <input type='text' placeholder='Email' onChange={onEmailChange} value={email} />
                <input type='text' placeholder='Phone Number' onChange={onPhoneChange} value={phone} />
                <input type='text' placeholder='What position are you applying for' onChange={onPositionChange} value={position} />
                <input type='text' placeholder='What is your preferred job location' onChange={onLocationChange} value={location} />
                <input type='text' placeholder='What is your expected CTC' onChange={onCtcChange} value={ctc} />
                <div className='file-selection'>
                    <span className='span-text'>Upload Resume</span>
                    <input type='file' onChange={onResumeChange} />
                    <span className='span-text'>Expected types are docx and pdf</span>
                </div>
                <button onClick={onFormSubmit}>Submit</button>
            </form>
            {
                showImage && <img src={UploadCV} />
            }
        </div>
    )
}
export default JobSeeker