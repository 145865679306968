import './Footer.css'
import { MdContactMail } from 'react-icons/md'

function Footer() {
    return (
        <div className='footer'>
            <div className='footer-text'>
                &#169;Sagacious Minds 2024
            </div>

            <div className='footer-text'>
                <MdContactMail color='white' size={25} />
                &nbsp;
                <a href='mailto:hr@sagaciousminds.com' className='cemail'>hr@sagaciousminds.com</a>
            </div>

        </div>
    )
}

export default Footer