import './Contact.css'
import Contactus from '../images/contactus.jpeg'
import { MdEmail } from 'react-icons/md'
import { useState } from 'react'
import { postData } from '../helper'

function Contact() {
    const [fullName, setFullName] = useState('')
    const [email, setEmail] = useState('')
    const [phone, setPhone] = useState('')
    const [message, setMessage] = useState('')
    const [error, setError] = useState(false)
    const [success, setSuccess] = useState('')

    const onFullNameChange = (e) => {
        setFullName(e.target.value)
    }

    const onEmailChange = (e) => {
        setEmail(e.target.value)
    }

    const onPhoneChange = (e) => {
        setPhone(e.target.value)
    }

    const onMessageChange = (e) => {
        setMessage(e.target.value)
    }

    const onFormSubmit = (e) => {
        e.preventDefault()
        if (!fullName || !email || !phone || !message) {
            setError(true)
            return
        }

        const formData = new FormData()
        formData.append('fullName', fullName)
        formData.append('email', email)
        formData.append('phone', phone)
        formData.append('message', message)
        const headers = {
            'Content-Type': 'multipart/form-data'
        }
        const response = postData('enquiry', formData, headers)
        response.then(res => {
            if (res.status !== 200) {
                setSuccess("Ooops something went wrong...")
                return
            }
            setSuccess(res.data.message)
            setFullName('')
            setEmail('')
            setPhone('')
            setMessage('')
            setError(false)
        }).catch(err => {
            setSuccess("Ooops something went wrong...")
        })

    }
    return (
        <div className="contact">
            <div className='contact-left'>
                <img src={Contactus} />
                <div>
                    <p className="contactus-title">
                        Contact Us
                    </p>
                    <p className="contactus-email">
                        <MdEmail size={20} />
                        <span>
                            &nbsp;&nbsp;hr@sagaciousminds.com
                        </span>
                    </p>
                </div>
            </div>
            <div className="seeker-form-div" style={{marginRight: "20px", padding: "20px"}}>
                <form className='seeker-form'>
                    {error && <span className='error'>Please fill all the fields</span>}
                    {success && <span className='success'>{success}</span>}
                    <input type='text'
                        placeholder='Full Name'
                        onChange={onFullNameChange}
                        value={fullName} />
                    <input
                        type='text'
                        placeholder='Email'
                        onChange={onEmailChange}
                        value={email} />
                    <input
                        type='text'
                        placeholder='Phone Number'
                        onChange={onPhoneChange}
                        value={phone} />
                    <textarea
                        placeholder='Message'
                        onChange={onMessageChange}
                        value={message} />
                    <button onClick={onFormSubmit}>Submit</button>

                </form>

            </div>

        </div>
    )
}
export default Contact