import './About.css'
import Aboutus from '../images/aboutus.jpg'
import Ourvision from '../images/ourvision.jpg'
import Ourmission from '../images/ourmission.jpg'
import Industries from '../images/image5.png'
import { useEffect, useState } from 'react'

function About() {

    const [showImage, setShowImage] = useState('alternate')

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth > 768) {
                setShowImage('alternate')
            } else {
                setShowImage('sequential')
            }
        }
        window.addEventListener('resize', handleResize)
        handleResize()
        return () => {
            window.removeEventListener('resize', handleResize)
        }
    }, [window.innerWidth])


    return (
        <div className="about">
            <div className="aboutus">
                {
                    showImage === 'sequential' && <img src={Aboutus} />
                }
                <div className="about-content">
                    <p className="about-content-heading">
                        About Us
                    </p>
                    <p>
                        Sagacious Minds is a fast growing Gurgaon based company working with the aim of customer satisfaction. We Advise management on the formulation and administration of plans and policies for human resource activities majorly Manpower. Act as an external consultant by analysing and recommending solutions to human resource issues relating to the assigned program or section area. We hire the best Talent for our clients & provide our services in PAN India and Abroad.
                    </p>
                    <p>
                        Sagacious Minds is a school of thought aspires to build immaculate HR services for small/Medium/Large businesses and platforms for corporates who are yearning to make a great impression and to dominate the business landscape. We are a formidable team of HR professionals helping you scale and sustain your businesses enabling long-term growth and profitability. We are one of the best HR Outsourcing Company who work closely with the management team & employees as HR Shared Services partners using tech-enabled tools with PAN India presence and Industry-wide experience for virtual hr services.
                    </p>
                </div>
                {
                    showImage === 'alternate' && <img src={Aboutus} />
                }
            </div>
            <div className="ourvision">
                <img src={Ourvision} />
                <div className="ourvision-content">
                    <p className="ourvision-content-heading">
                        Our Vision
                    </p>
                    <p>
                        To become the top notch executive search firm, working across the boundaries of the geographical dimension focusing the quality, time and client’s interest and delivering best suited business solutions.
                    </p>
                </div>
            </div>
            <div className="ourmission">
                {
                    showImage === 'sequential' && <img src={Ourmission} />
                }
                <div className="ourmission-content">
                    <p className="ourmission-content-heading">
                        Our Mission
                    </p>
                    <p>
                        To hire the Right man for right job
                    </p>
                    <p>
                        We want to be the preferred recruitment partner for our valued clients & deliver expeditious and integrity services to clients.
                    </p>
                </div>
                {
                    showImage === 'alternate' && <img src={Ourmission} />
                }
            </div>
            <div className="industries-we-serve">
                <p>
                    Industries We Serve
                </p>
                <img src={Industries} />
            </div>
        </div>
    )
}
export default About