import { useEffect, useState } from "react";
import { getData, postData } from "../helper";
import './JobSeeker.css'

function Admin() {

    const [positionId, setPositionId] = useState('None')
    const [positions, setPositions] = useState({})
    const [verifyToken, setVerifyToken] = useState(false)
    const [token, setToken] = useState('')
    const [positionTitle, setPositionTitle] = useState('')
    const [location, setLocation] = useState('')
    const [ctc, setCtc] = useState('')
    const [experience, setExperience] = useState('')
    const [skills, setSkills] = useState('')
    const [description, setDescription] = useState('')
    const [status, setStatus] = useState('')

    const onPositionTitleChange = (e) => {
        setPositionTitle(e.target.value)
    }

    const onLocationChange = (e) => {
        setLocation(e.target.value)
    }

    const onCtcChange = (e) => {
        setCtc(e.target.value)
    }

    const onExperienceChange = (e) => {
        setExperience(e.target.value)
    }

    const onSkillsChange = (e) => {
        setSkills(e.target.value)
    }

    const onDescriptionChange = (e) => {
        setDescription(e.target.value)
    }

    const onStatusChange = (e) => {
        setStatus(e.target.value)
    }

    const onChangeToken = (e) => {
        setToken(e.target.value)
    }

    const onVerifyToken = () => {
        const headers = {
            "Authorization": `Bearer ${token}`
        }
        const response = getData('verify-token', headers)
        response.then(res => {
            if ("status_code" in res.data && res.data.status_code !== 200) {
                alert('Invalid Token')
                return
            }

            if (res.status !== 200) {
                return
            }

            setVerifyToken(true)
            const headers = {
                "Authorization": `Bearer ${token}`
            }

            const response_positions = getData('positions', headers)
            response_positions.then(res => {
                if (res.status !== 200) {
                    return
                }
                setPositions(res.data)
            }).catch(err => {
                console.log(err)
            })
        }).catch(err => {
            console.log(err)
        })

    }

    const addPosition = () => {

        if (!positionTitle || !location || !ctc || !experience || !skills || !description || !status) {
            alert('Please fill all fields')
            return
        }

        const formData = new FormData()
        formData.append('positionId', positionId)
        formData.append('title', positionTitle)
        formData.append('location', location)
        formData.append('ctc', ctc)
        formData.append('experience', experience)
        formData.append('skills', skills)
        formData.append('description', description)
        formData.append('status', status)

        const header = {
            "Authorization": `Bearer ${token}`,
            'Content-Type': 'multipart/form-data'
        }
        const response = postData('positions', formData, header)
        response.then(res => {
            if ("status_code" in res.data && res.data.status_code !== 200) {
                alert('Invalid Token')
                return
            }
            if (res.status !== 200) {
                return
            }
            setPositions({ ...positions, ...res.data })
            alert('Position Added Successfully')
        }).catch(err => {
            console.log(err)
        })
    }

    const onAddPosition = (e) => {
        e.preventDefault()
        addPosition()
    }

    const onPositionEdit = (pid) => {
        setPositionId(pid)
        setPositionTitle(positions[pid].title)
        setLocation(positions[pid].location)
        setCtc(positions[pid].ctc)
        setExperience(positions[pid].experience)
        setSkills(positions[pid].skills)
        setDescription(positions[pid].description)
        setStatus(positions[pid].status)
    }

    return (
        <div style={{ width: "80%" }}>
            {
                !verifyToken && <div>
                    <h4>Please verify token:</h4>
                    <input type='password'
                        placeholder='Enter Admin Password'
                        onChange={onChangeToken}
                        value={token} />
                    <button onClick={onVerifyToken}>Verify</button>
                </div>

            }
            {
                verifyToken && Object.keys(positions).length > 0 &&
                <div>
                    <h3>Current Positions</h3>
                    <div className='outer-position'>
                        {
                            Object.keys(positions).map((key, index) => {
                                return (
                                    <div key={index} className="position-card" onClick={() => onPositionEdit(key)}>
                                        <div className='position-detail'>
                                            <div className="position-title">{positions[key].title}</div>
                                        </div>
                                    </div>
                                )
                            }
                            )
                        }
                    </div>
                </div>

            }
            {
                verifyToken &&
                <div style={{height: "100vh"}}>
                    <h3>Add Position</h3>
                    <div className="seeker-form-div">

                        <form className='seeker-form'>
                            <input type='text'
                                placeholder='Position Title'
                                onChange={onPositionTitleChange}
                                value={positionTitle} />
                            <input type='text'
                                placeholder='Location'
                                onChange={onLocationChange}
                                value={location} />
                            <input type='text'
                                placeholder='CTC'
                                onChange={onCtcChange}
                                value={ctc} />
                            <input type='text'
                                placeholder='Experience Required'
                                onChange={onExperienceChange}
                                value={experience} />
                            <input type='text'
                                placeholder='Skills Required'
                                onChange={onSkillsChange}
                                value={skills} />
                            <textarea type='text'
                                placeholder='Job Description'
                                onChange={onDescriptionChange}
                                value={description} />
                            <input type='text'
                                placeholder="Status"
                                onChange={onStatusChange}
                                value={status} />
                            <button onClick={onAddPosition}>Add Position</button>
                        </form>
                    </div>
                </div>
            }
        </div>
    );
}

export default Admin;