import { useState, useEffect } from 'react';
import { getData } from '../helper';
import './Positions.css'
import PositionDetail from './PositionDetail';

function Positions() {

    const [positions, setPositions] = useState({})

    const [showPositionDetail, setShowPositionDetail] = useState({})

    const onPositionClick = (position) => {
        setShowPositionDetail(positions[position])
    }

    useEffect(() => {
        const response = getData('positions')
        response.then(res => {
            if (res.status !== 200) {
                return
            }
            setPositions(res.data)
        }).catch(err => {
            console.log(err)
        })
    }, [])

    return (
        <div className='positions'>
            <h1>Current Openings</h1>
            <div className="position-block">
                {
                    Object.keys(positions).length > 0 ?
                        <div className='outer-position'>
                            {
                                Object.keys(positions).map((key, index) => {
                                    return (
                                        <div key={index} className="position-card" onClick={() => onPositionClick(key)}>
                                            <div className='position-detail'>
                                                <div className="position-title">{positions[key].title}</div>
                                                <div>{positions[key].location}</div>
                                            </div>
                                        </div>
                                    )
                                }
                                )
                            }
                        </div>
                        : <p className='outer-position'>No positions available</p>
                }
                {
                    Object.keys(showPositionDetail).length !== 0 && <PositionDetail position={showPositionDetail} />
                }
                {
                    Object.keys(showPositionDetail).length === 0 && <p className='position-detail'>No positions selected</p>
                }
            </div>
        </div>
    );
}

export default Positions;