import './Nav.css'
import { NavLink } from 'react-router-dom'
import { MdMenu } from 'react-icons/md'
import { useState, useEffect } from 'react'

function Nav() {

    const [showNav, setShowNav] = useState('flex')
    const [isDesktop, setIsDesktop] = useState(window.innerWidth > 768)

    const onNavClick = () => {
        if (isDesktop) return
        setShowNav(showNav === 'none' ? 'flex' : 'none')
    }

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth > 768) {
                setShowNav('flex')
                setIsDesktop(true)
            } else {
                setShowNav('none')
                setIsDesktop(false)
            }
        }
        window.addEventListener('resize', handleResize)
        handleResize()
        return () => {
            window.removeEventListener('resize', handleResize)
        }
    }, [window.innerWidth])

    return (
        <div className='navbar'>
            <div className='resp-menu' onClick={onNavClick}>
                <MdMenu size={30} />
            </div>
            <nav className='nav-items' style={{ display: showNav }}>
                <NavLink className={({ isActive, isPending }) =>
                    isPending ? "nav-items-pending" : isActive ? "nav-items-active" : "nav-items-pending"
                } to="/" exact onClick={onNavClick}>Home</NavLink>
                <NavLink className={({ isActive, isPending }) =>
                    isPending ? "nav-items-pending" : isActive ? "nav-items-active" : "nav-items-pending"
                } to="/about" exact onClick={onNavClick}>About</NavLink>
                <NavLink className={({ isActive, isPending }) =>
                    isPending ? "nav-items-pending" : isActive ? "nav-items-active" : "nav-items-pending"
                } to="/services" exact onClick={onNavClick}>Services</NavLink>
                <NavLink className={({ isActive, isPending }) =>
                    isPending ? "nav-items-pending" : isActive ? "nav-items-active" : "nav-items-pending"
                } to="/contact" exact onClick={onNavClick}>Contact</NavLink>
                <NavLink className={({ isActive, isPending }) =>
                    isPending ? "nav-items-pending" : isActive ? "nav-items-active" : "nav-items-pending"
                } to="/job-seeker" exact onClick={onNavClick}>Job Seeker</NavLink>
                <NavLink className={({ isActive, isPending }) =>
                    isPending ? "nav-items-pending" : isActive ? "nav-items-active" : "nav-items-pending"
                } to="/positions" exact onClick={onNavClick}>Openings</NavLink>
                {/* <NavLink to="/faq" exact onClick={onNavClick}>FAQ</NavLink>
                <NavLink to="/testimonials" exact onClick={onNavClick}>Testimonials</NavLink> */}
            </nav>
        </div>
    )
}

export default Nav