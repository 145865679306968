import './JobSeeker.css';
import JobSeeker from './JobSeeker';

function JobSeekerPage() {
    return (
        <div className='seeker'>
            <div className='job-seeker'>
                <div className='job-seeker-title'>
                    Upload Resume
                </div>
                <div className='job-seeker-content'>
                    Elevate your job search by uploading your resume. Our platform connects you<br />
                    with tailored opportunities that match your skills and experience. Get ready<br />
                    to take next step in your career journey.
                </div>
            </div>
            <JobSeeker showImage={true}/>
        </div>
    );
}

export default JobSeekerPage;