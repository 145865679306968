import "./Home.css"
import FrontPage from "../images/front-page-2.jpg"
import DepthImage from "../images/image12.png"
import Streamlined from "../images/image13.png"
import Outcome from "../images/image14.png"
import Trust from "../images/image15.png"
import Methodology from "../images/image17.png"
import Talent from "../images/image18.png"
import TimeSaver from "../images/image19.png"
import Expertise from "../images/image20.png"

function Home() {
    return (
        <div className="home-page">
            <div className="home">
                <div>
                    <img src={FrontPage} />
                    <div className="text-on-image">
                        <p className="welcome">Welcome to</p>
                        <p className="welcome">Sagacious Minds</p>
                        <p>Your search stops here</p>
                    </div>

                </div>
            </div>
            {/* E9B332 */}
            <div className="home-2">
                <p className="home-title" style={{color: "#FE5967"}}><b>Sagacious Minds</b></p>
                <p className="home-title-2">Why you should choose us</p>
                <p className="home-para">Choosing our HR Consultancy means partnering with a team that understands the unique challenges and dynamics of your business. We are not just service providers; we are strategic allies who are committed to your success.  Throughout the tumultuous period of the Covid-19 pandemic, our organization has exemplified resilience and innovation, standing tall amidst global uncertainty. Our success is a testament to the hard work and smart strategies employed by our dedicated team. Our consultancy is distinguished by our tailored approach to HR solutions, ensuring that they align perfectly with your business objectives. We bring a wealth of experience and a proven track record of enhancing organizational performance and employee moral.
When it comes to recruitment, our consultancy stands out as the best choice for businesses seeking top talent. With our deep industry knowledge and extensive network, we expertly match the right candidates to the right roles, ensuring a perfect fit for your company’s needs and culture.</p>
            </div>
            <div className="home-3">
                <p className="home-3-p-1">
                    The Right Employment Agency for You
                </p>
                <p className="home-3-p-2">
                    Excellence and Success
                </p>
            </div>
            <div className="home-4">
                <div className="home-4-div">
                    <img src={DepthImage} />
                    <span>
                        In-Depth Expertise in Critical Business Areas
                    </span>
                </div>
                <div className="home-4-div">
                    <img src={Streamlined} />
                    <span>
                        Streamlined Business Processes
                    </span>
                </div>
                <div className="home-4-div">
                    <img src={Outcome} />
                    <span>
                        Outcome-Oriented
                    </span>
                </div>
                <div className="home-4-div">
                    <img src={Trust} />
                    <span>
                        Trust and Progress: Our Core Values for Success
                    </span>
                </div>
            </div>
            <div className="home-5">
                <div className="home-5-heading">Our Methodology</div>
                <div className="home-5-first">
                    <div className="home-5-div">
                        <img src={Methodology} className="img-all" />
                        <p>
                            <b>
                                Methodology
                            </b>
                        </p>
                        <span>
                            We help organizations improve their performance, productivity, and bottom-line by re-engineering their processes. Our solutions integrate research with 5th gen tech to develop solutions that are specifically and explicitly connected to the critical business issues that organizations face.
                        </span>
                    </div>
                    <div className="home-5-div">

                        <img src={Talent} className="img-talent" />
                        <p>
                            <b>
                                Extended Reach For The Best Talent
                            </b>
                        </p>
                        <span>
                            When you hand over work to us, we use all the resources available with us to get a widerselection of candidates. Different approaches help in getting better people quickly
                        </span>
                    </div>
                    <div className="home-5-div">

                        <img src={TimeSaver} className="img-all" />
                        <p>
                            <b>
                                Time Saver
                            </b>
                        </p>
                        <span>
                            As we take care of the recruiting process for the clients, your HR team only need to specify your requirement and conduct interviews for the final selection. You don’t have to spare your time and attention to the whole process.
                        </span>
                    </div>
                    <div className="home-5-div">

                        <img src={Expertise} className="img-all" />
                        <p>
                            <b>
                                Sector Expertise
                            </b>
                        </p>
                        <span>
                            We have experts who know the requirements of different sectors. This makes it possible for us to find the most suitable persons for your company.
                        </span>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Home