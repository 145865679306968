import './Positions.css';
import { useState } from 'react';
import JobSeeker from './JobSeeker';

function PositionDetail({ position }) {

    const [isApplied, setIsApplied] = useState(false)

    const onApplyClick = () => {
        setIsApplied(!isApplied)
    }

    return (
        <div className="position-detail-2">
            {
                isApplied && <div className="position-applied">
                    <div className='previous' onClick={onApplyClick}>&#8249; Back</div>
                    <JobSeeker appliedCtc={position.ctc}
                        appliedLocation={position.location}
                        appliedPosition={position.title} />
                </div>
            }
            {
                !isApplied &&

                <div className='position-detail-2'>
                    <div>
                        <h1>{position.title}</h1>
                        <div><b>Location: </b>{position.location}</div>
                        <div><b>CTC:</b> {position.ctc}</div>
                        <div><b>Experience Required:</b>{position.experience}</div>
                        <div className='skills'>Skills Required:</div>
                        <div>{position.skills}</div>
                        <div className='skills'>Job Description:</div>
                        <div>{position.description}</div>
                    </div>
                    <button onClick={onApplyClick}>Apply</button>
                </div>
            }
        </div>
    );
}

export default PositionDetail;