import './Services.css'

import Recuritment from '../images/image1.png'
import Staffing from '../images/image2.png'
import HR from '../images/image3.png'
import Career from '../images/image4.png'

function Services() {
    return (
        <div className="services">
            <p className="services-title">
                What We Offer
            </p>
            <div className="all-services">
                <div className="service">
                    <img src={Recuritment} />
                    <p className="service-title">Recruitment</p>
                    <p>
                    We help you connect with talented and highly skilled workforce for entry level, middle level, and senior-level positions in your organization.
                    </p>
                </div>
                <div className="service">
                    <img src={Staffing} />
                    <p className="service-title">Staffing</p>
                    <p>
                    We offer flexible staffing services to our clients to help them increase their work efficiency and profit.
                    </p>

                </div>
                <div className="service">
                    <img src={HR} />
                    <p className="service-title">HR Outsourcing</p>
                    <p>
                    We help organizations in HR outsourcing, also known as bulk hiring. We can provide you a huge number of employees in the limited time slot.
                    </p>

                </div>
                <div className="service">
                    <img src={Career} />
                    <p className="service-title">Career Management</p>
                    <p>
                    AOP offers complete support to people looking for effective ways to boost their careers and move to better job profiles.
                    </p>

                </div>
            </div>
        </div>
    )
}
export default Services